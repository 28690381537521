// @ts-nocheck
import * as React from 'react';
import { Nav as NavContainer, NavItem, Button } from 'reactstrap';
import RenderLink from './RenderLink';
import './assets/css/style.css';
import withToast from "../HOC/withSnackBar.Web";

import NavController from './NavController.web'
export const configJSON = require('./config')

interface NavProps {

}
class NavComponent extends NavController {
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            // @ts-ignore
            adminuser : null,
            loading : true,
            links: [
                {
                    id: 'home',
                    label: 'Home',
                    iconName: 'icon-union',
                    link: '/'
                },
                {
                    id: 'order',
                    label: 'Orders',
                    iconName: 'icon-orders',
                    link: '/orders'
                },

                {
                    id: 'inventory',
                    label: 'Inventory',
                    iconName: 'icon-products',
                    subLinks: [
                        {
                            id: 'product',
                            label: 'Products',
                            link: '/products'
                        },
                        {
                            id: 'category',
                            label: 'Categories',
                            link: '/inventory/categories'
                        },
                        {
                            id: 'brand',
                            label: 'Brands',
                            link: '/inventory/brands'
                        },
                        {
                            id: 'bulk-upload',
                            label: 'One Click Upload',
                            link: '/inventory/bulk-uploads'
                        }

                    ]
                },
                {
                    id: 'user',
                    label: 'Customers',
                    iconName: 'icon-customer',
                    link: '/customers'
                },
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: 'brand setting',
                            label: 'Branding',
                            link: '/website-and-emails/branding'
                        },
                        {
                            id: 'image',
                            label: 'Image Catalogue',
                            link: '/website-and-emails/image-catalogue'
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/website-and-emails/email-templates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/website-and-emails/static-pages'
                        },
                        {
                            id: 'coustomer',
                            label: 'Customer Feedback',
                            link: '/website-and-emails/customer-feedback'
                        }
                    ]
                },
                {
                    id: 'business',
                    label: 'Business Settings',
                    iconName: 'icon-settings',
                    subLinks: [
                        {
                            id: 'store',
                            label: 'Store details',
                            link: '/business-settings/store-details'
                        },
                        {
                            id: 'tax',
                            label: 'Taxes',
                            link: '/business-settings/taxes'
                        },
                        {
                            id: 'shipping',
                            label: 'Shipping',
                            link: '/business-settings/shipping'
                        },
                        {
                            id: 'payment',
                            label: 'Payment',
                            link: '/business-settings/payment'
                        },
                        {
                            id: 'coupon',
                            label: 'Promo Code',
                            link: '/business-settings/promo-code'
                        },
                        {
                            id: 'notifications',
                            label: 'Notifications',
                            link: '/business-settings/notifications'
                        },
                        {
                            id: 'mobile',
                            label: 'Mobile app',
                            link: '/business-settings/mobile-app'
                        },
                        {
                            id: 'otherIntegrations',
                            label: 'Other Integrations',
                            link: '/business-settings/other-integrations'
                        }
                    ]
                },
                {
                    id: 'account',
                    label: 'Account',
                    iconName: 'icon-account',
                    subLinks: [
                        {
                            id: 'accountoverview',
                            label: 'Overview',
                            iconName: 'icon-settings-circle',
                            link: '/account/overview'
                        },
                        {
                            id: 'profile',
                            label: 'Profile',
                            iconName: 'icon-user',
                            link: '/account/detail'
                        },
                        {
                            id: 'adminUsers',
                            label: 'Admin users',
                            iconName: 'icon-selection',
                            link: '/account/admin-users'
                        },

                    ]
                }
            ],
            tierType: "",
        }
    }

    componentDidMount() {
        if(localStorage.getItem("admintoken") && localStorage.getItem("adminuser")){
            this.setState({
                adminuser : JSON.parse(localStorage.getItem("adminuser")) || {},
                loading : false
            })
        }
    }
    render() {
        // @ts-ignore
        const adminuser = this.state.adminuser;
        const permissions = adminuser?.data?.attributes?.permissions;
        const permissionLinks = (data: any) => {
            if (data?.id && data?.subLinks) {
                const value1 = [];
                data?.subLinks?.map((values: any) => permissions?.find(permission => {
                    if (permission === values?.id) {
                        value1?.push(values)
                        return values
                    }
                }))
                data.subLinks = value1;
                return data?.subLinks?.length ? data : undefined;
            }
            if (data) {
                const value2 = [];
                permissions?.find(permission => {
                    if (permission === data?.id) {
                        value2?.push(data)
                        return data
                    }
                })
                return value2[0];
            }

        }
        // @ts-ignore
        return (
            <NavContainer
                className='admin-nav'
                tabs
                vertical
            >
                {permissions && this.state?.links.length &&
                    this.state?.links.map((data: any, index: number) => (
                        permissions.includes("all")
                            ? <RenderLink data={data} tierType={this.state.tierType } />
                            : (index === 0 && permissions.includes("order")) ? <RenderLink data={this.state.links[0]} tierType={this.state.tierType } />
                            : permissionLinks(data) !== undefined
                            ? <RenderLink data={permissionLinks(data)} tierType={this.state.tierType } /> : ''
                    ))
                }
            </NavContainer>
        );

    }


}

export const Nav = withToast(NavComponent);